const config = {
    oidc: {
      clientId: '0oa1wjsnbie317BZP0h8',
      issuer: 'https://atriahealth.okta.com/',
      redirectUri: `https://ai-staging.atria.org/login/callback`,

      // redirectUri: `http://localhost:3000/login/callback`,
      // redirectUri: `http://52.87.184.174:3000/login/callback`,
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
    },
  };

  
  
  export default config;
  