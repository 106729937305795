import React, { useState, useEffect, useContext } from "react";
import ReactMarkdown from "react-markdown";
import { jsPDF } from "jspdf";
import axios from "axios";
import Plot from "react-plotly.js";
import UserContext from "../context/UserContext";
import "../Newpage.css";
import Sidebar from "../Sidebar";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { useOktaAuth } from "@okta/okta-react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FileSaver from "file-saver";

const NewPage = () => {
  console.log("sotrage newpage",localStorage)
  // const { authState } = useOktaAuth();

  const [patientId, setPatientId] = useState("");
  const [physicianReport, setPhysicianReport] = useState("");
  const [medicalReport, setMedicalReport] = useState("");
  const [abnormalityReport, setAbnormalityReport] = useState("");
  const [executiveSummary, setExecutiveSummary] = useState("");
  const [appointmentText, setAppointmentText] = useState("");
  const [appointmentDf, setAppointmentDf] = useState("");
  const [spikesAndTrends, setSpikesAndTrends] = useState({});
  const [newApiData, setNewApiData] = useState({}); //snowflake
  const [gdocsLink, setGdocsLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [reportsFetched, setReportsFetched] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const [chatBotInput1, setChatBotInput1] = useState("");
  const [selectedOption, setSelectedOption] = useState("Medical");
  const [chatBotResponse, setChatBotResponse] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [medicalResponse, setMedicalResponse] = useState("");
  const [markCitationResponse, setMarkCitationResponse] = useState(""); //this is for executive summary citation mapping
  const [medicalCitationResponse, setMedicalCitationResponse] = useState("");
  // const [medicationFollowUpData, setMedicationData] = useState("");
  const [medicationFollowUpData, setMedicationFollowupData] = useState(null);
  
  const [isOpen, setIsOpen] = useState(false);
  const [cardioData, setCardioData] = useState("");
  const [cancerScreeningData, setCancerScreenData] = useState("");
  const [bodyCompositionData, setBodyComposition] = useState("");
  const [labTestCitation, setLabtestCitation] = useState("");
  const [labTestData, setLabTestData] = useState(null);
  const [cardioCitationResponse, setCardioCitation] = useState("");
  const [cancerCitationResponse, setCancerCitation] = useState("");
  const [bodyCitationResponse, setBodyCitation] = useState("");
  const [appError, setAppError] = useState("");
  const [reportGenerated, setReportGenerated] = useState(false);
  const [isGsheet, setisGsheet] = useState(false);
  const [gsheetSMD, setgsheetSMD] = useState("");
  const[followUpCitation,setfollowUpCitation]=useState("");

  const [AbnormalityError, setAbnormalityError] = useState("");

  const [PhysicianError, setPhysicianError] = useState("");
  const [ExecutiveSummaryError, setExecutiveSummaryError] = useState("");
  const [MedicationError, setMedicationError] = useState("");
  const [FollowupError, setfollowupError] = useState("");
  const [CardioError, setCardioError] = useState("");
  const [CancerScreeningError, setCancerScreeningError] = useState("");
  const [BodyCompositionError, setBodyCompositionError] = useState("");
  const [AppointmentError, setAppointmentError] = useState("");
  const [SpikesAndTrendsError, setSpikesAndTrendsError] = useState("");
  const [MedicalFollowupError, setMedicalFollowupError] = useState("");
  const [NewApiDataError, setNewApiDataError] = useState("");
  const [medicationParsedData, setMedicationParsedData] = useState([]);
  const [followupParsedData, setFollowupParsedData] = useState("");


  const controller = new AbortController();
  const signal = controller.signal;
  
  setTimeout(() => controller.abort(), 300000)
  const location = useLocation();
  // const { firstName, lastName ,emailID} = location.state || {};
  const [executiveResponse, setExecutiveResponse] = useState("");
  // const { user } = useContext(UserContext);
  const { user, sessionID, firstName, lastName, email } =
    useContext(UserContext);

  const [showChatBot, setShowChatBot] = useState(false);


  const generateText = (data) => {
    // Check if data is null, undefined, or not an array
    if (!data || !Array.isArray(data)) {
      console.error('Invalid data passed to generateText:', data);
      return "";
    }
  
    // If data is an empty array, return empty string
    if (data.length === 0) {
      return "";
    }
  
    return data.map((item, index) => {
      // Ensure each item has the expected structure
      const name = item.NAME || "Unnamed Item";
      const instructions = item.INSTRUCTIONS ? item.INSTRUCTIONS : "N/A";
      return `${index + 1}. ${name} - ${instructions}`;
    }).join("\n\n");
  };
  // useEffect to combine and process data
  useEffect(() => {
    try {


      // Log the raw data to understand its structure
      // console.log('Medication Parsed Data:', medicationParsedData);
      // console.log('Followup Parsed Data:', followupParsedData);
  
      // Ensure you're passing an array to generateText
      const medicationText = generateText(
        Array.isArray(medicationParsedData[0]) 
          ? medicationParsedData[0] 
          : (medicationParsedData[0] ? [medicationParsedData[0]] : [])
      );
      var medicationAndFollowUp=`### Current Medications\n\n${medicationText}`
      setMedicationFollowupData(medicationParsedData[0]);
      
      // console.log(`${medicationText}\n${followupParsedData}`);

      // console.log(medicationFollowUpData,"🍔✅");
    } catch (error) {
      console.error('Error in generating medication text:', error);
      setMedicationFollowupData("");
    }
  }, [medicationParsedData, followupParsedData]);

  // New state for edit mode
  const [editState, setEditState] = useState({
    physicianReport: false,
    medicalReport: false,
    executiveSummary: false,
    appointmentText: false,
    medicationFollowUpData: false,
    markCitationResponse: false,
  });

  const [tempDict, setTempDict] = useState({
    patient_id: patientId,
    executive_summary: executiveSummary,
    medical_summary: medicalResponse,
    appointment: appointmentText,
    medications:medicationFollowUpData,
    followups: followupParsedData,
   
  });

 
  useEffect(() => {
    const tempDict = {
      patient_id: patientId,
      executive_summary: executiveSummary,
      medical_summary: medicalResponse,
      appointment: appointmentText,
      medications:medicationFollowUpData,
      followups: followupParsedData,
    };
    setTempDict(tempDict);
  }, [
    patientId,
    executiveSummary,
    medicalResponse,
    appointmentText,
    medicationFollowUpData,
    followupParsedData
  ]);

  const useSidebarState = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const handleSidebarToggle = (isOpen) => {
      setIsSidebarOpen(isOpen);
    };

    return { isSidebarOpen, handleSidebarToggle };
  };

  const [scrolledToTop, setScrolledToTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setScrolledToTop(true);
      } else {
        setScrolledToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log("Updated medicationParsedData:🍔🦄", medicationParsedData);
  }, [medicationParsedData]);

  const handleMoveToTop = () => {
    if (scrolledToTop) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };


  

  const fetchReport = async (
    url,
    setReport,
    module = "default",
    onComplete
  ) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal,
      });

      if (!response.ok) {
        setReport(`Error fetching ${module}`);
        throw new Error("Network response was not ok");
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let result = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += value;
        setReport(result);
      }

      if (setReport === setExecutiveSummary) {
        console.log("inside executive summary");
        try {
          const markCitationResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
            {
              summary_data: result,
              patient_id: patientId,
            }
          );
          setMarkCitationResponse(await markCitationResponse.data);
       
        } catch (err) {
          console.error("Error posting executive summary:", err);
        }
      } else if (setReport === setBodyComposition) {
        console.log("inside body composition");
        try {
          setBodyComposition((prevState) => result);
          console.log("🤷‍♂️");
          const bodyCitationResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
            {
              summary_data: result,
              patient_id: patientId,
            }
          );
          setBodyCitation(await bodyCitationResponse.data);
        
          setLabTestData("1");
        } catch (err) {
          console.error("Error posting body composition:", err);
        }
      } else if (setReport === setCancerScreenData) {
        console.log("inside cancer screening");
        try {
          setCancerScreenData((prevState) => result);
          const cancerCitationResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
            {
              summary_data: result,
              patient_id: patientId,
            }
          );
          setCancerCitation(await cancerCitationResponse.data);
        } catch (err) {
          console.error("Error posting cancer screening:", err);
        }
      } else if (setReport === setCardioData) {
        try {
          setCardioData(() => result);
          const cardioCitationResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
            {
              summary_data: result,
              patient_id: patientId,
            }
          );
          setCardioCitation(await cardioCitationResponse.data);
        } catch (err) {
          console.error("Error posting cardio data:", err);
        }
      }


      else if(setReport === setMedicationParsedData ){
       

        try {
          const parsedData = JSON.parse(result);
          // console.log("Parsed Medication Data:", parsedData);
          setMedicationParsedData(parsedData);
          // console.log(result,"👽👽");
       
        } catch (err) {
          console.error("Error posting follow up data:", err);
          console.error("Error parsing medication data:");
          setMedicationParsedData([]);
          setMedicationError("Unable to parse medication data");
        }
        // console.log("🍕medication🍕",medicationParsedData);
      }
      else if(setReport === setFollowupParsedData){
        // console.log("🍕followup🍕");

        try {
          setFollowupParsedData(() => result);
          const followupCitationResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
            {
              summary_data: result,
              patient_id: patientId,
            }
          );
          setfollowUpCitation(await followupCitationResponse.data);
        } catch (err) {
          console.error("Error posting follow up data:", err);
        }
      }

      if (setReport === setLabTestData) {
        try {
          const lab_test_url = `${process.env.REACT_APP_API_URL}/Atria/additional_lab_test?patient_id=${patientId}`;

          const lab_test_response = await fetch(lab_test_url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              cardiovascular_system: cardioData,
              cancer_screening: cancerScreeningData,
              body_composition: bodyCompositionData,
            }),
          });

          if (lab_test_response.ok) {
            const labData = await lab_test_response.json();
            setLabTestData(labData.result);
           // setReportsFetched(true);
          } else {
            throw new Error("Unable to fetch lab result");
          }
        } catch (err) {
          console.error("Error fetching lab test data:", err);
          setLabTestData("Unable to fetch lab result");
        }
      }

      if (onComplete) {
        onComplete(result);
      }
    } catch (err) {
      console.error("Error fetching the report:", err);
      // setError("Failed to fetch the report...aa");
    }
  };

  const [labTestDataFetched, setLabTestDataFetched] = useState(false);

  useEffect(() => {
    const fetchLabTestData = async () => {
      if (labTestData === "1") {
        const lab_test_url = `${process.env.REACT_APP_API_URL}/Atria/additional_lab_test?patient_id=${patientId}`;
        try {
          const lab_test_response = await fetch(lab_test_url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              cardiovascular_system: cardioData,
              cancer_screening: cancerScreeningData,
              body_composition: bodyCompositionData,
            }),
          });

          if (lab_test_response.ok) {
            const labData = await lab_test_response.json();
            setLabTestData(labData.result);

            const citationResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
              {
                summary_data: labData.result,
                patient_id: patientId,
              }
            );
            setLabtestCitation(citationResponse.data);

            setMedicalResponse(
              `${cardioData}\n\n${cancerScreeningData}\n\n${bodyCompositionData}\n\n${labData.result}`
            );

            // Set the state to indicate lab test data has been fetched
            setLabTestDataFetched(true);
             setReportsFetched(true);
          } else {
            throw new Error("Unable to fetch lab result");
          }
        } catch (err) {
          console.error("Error fetching lab test data:", err);
          setLabTestData("Unable to fetch lab result");
          setLabTestDataFetched(true); // Ensure state is set even if an error occurs
        }
      } else {
        setLabTestDataFetched(true); // Ensure state is set if condition is not met
      }
    };

    const handleGsheetLog = async () => {
      if (isGsheet && labTestData !== "1") {
        try {
          const timestamp = new Date()
            .toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            })
            .replace(/[/,:]/g, ":");

            









          const fileName = `${patientId} PHA (${timestamp})`;
          const gsheet_MedicalResponse = `${cardioData}\n\n${cancerScreeningData}\n\n${bodyCompositionData}\n\n${labTestData}`;

          const gsheetLogUrl = `${process.env.REACT_APP_API_URL}/Atria/gsheet_log`;
          const gsheetLogData = {
            first_name: firstName,
            last_name: lastName,
            patient_id: patientId,
            email: email,
            service: "PHA",
            sessionId: sessionID,
            title: fileName,
            physcian_summary: physicianReport,
            executive_summary: executiveSummary,
            medical_summary: gsheet_MedicalResponse,
            medications:medicationFollowUpData,
            followups: followupParsedData,
            appointment: appointmentText,
            spikes_and_trends: spikesAndTrends,
          };


          const controller = new AbortController();
          const signal = controller.signal;
    
          // Set a timeout to abort the request after 5 minutes
          setTimeout(() => controller.abort(), 300000); // 5 minutes in milliseconds
    
          // Make the fetch request with the signal
          const response = await fetch(`${process.env.REACT_APP_API_URL}/Atria/sessionID`, { signal });
          if (response.ok) {
          await axios.post(gsheetLogUrl, gsheetLogData);
          setisGsheet(false);
          }
          else{
            console.error("Fetch request failed:", response.statusText);
          }
        } catch (apiError) {
          console.error("API call error:", apiError);
        }
      }
    };

    // Call fetchLabTestData and wait for it to complete
    fetchLabTestData().then(() => {
      handleGsheetLog(); // Only call handleGsheetLog after fetchLabTestData completes
    });

 
  }, [
    executiveSummary,
    cardioData,
    bodyCompositionData,
    cancerScreeningData,
    labTestData,
    isGsheet,
  ]);

  const handleFetchReports = async () => {
    // Reset state to initial values
    setPhysicianReport("");
    setMedicalReport("");
    setAbnormalityReport("");
    setExecutiveSummary("");
    setAppointmentText("");
    setAppointmentDf("");
    setSpikesAndTrends({});
    setNewApiData({});
    setGdocsLink(null);
    setReportsFetched(false);
    setError("");
    setMarkCitationResponse("");
  

    // Reset error states
    setAbnormalityError("");
    setPhysicianError("");
    setExecutiveSummaryError("");
    setMedicationError("");
    setCardioError("");
    setCancerScreeningError("");
    setBodyCompositionError("");
    setAppointmentError("");
    setSpikesAndTrendsError("");
    setNewApiDataError("");

    setLoading(true);

    try {
      const checkPatientUrl = `${process.env.REACT_APP_API_URL}/Atria/check_patient_id?patient_id=${patientId}`;
      const checkPatientResponse = await fetch(checkPatientUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const isPatient = await checkPatientResponse.json();
      if (isPatient === true) {
        // Fetch Abnormality Report
        try {
          const abnormalityUrl = `${process.env.REACT_APP_API_URL}/Atria/abnormality_tables?patient_id=${patientId}`;
          await fetchReport(
            abnormalityUrl,
            setAbnormalityReport,
            "Abnormality Report",
            (result) => {
              if (result.includes("KeyError")) {
                setAbnormalityReport(
                  "Sorry, unable to proceed with your request"
                );
              }
            }
          );
        } catch (error) {
          console.error("Error fetching Abnormality Report:", error);
          setAbnormalityError("Error fetching Abnormality Report");
        }

        // Fetch Physician Report
        try {
          const physicianUrl = `${process.env.REACT_APP_API_URL}/Atria/physician_reference?patient_id=${patientId}`;
          await fetchReport(
            physicianUrl,
            setPhysicianReport,
            "Physician Report",
            (result) => {
              if (result.includes("KeyError")) {
                setPhysicianReport(
                  "Sorry, unable to proceed with your request"
                );
              }
            }
          );
        } catch (error) {
          console.error("Error fetching Physician Report:", error);
          setPhysicianError("Error fetching Physician Report");
        }

        // Fetch Executive Summary
        try {
          const executiveUrl = `${process.env.REACT_APP_API_URL}/Atria/executive_summary?patient_id=${patientId}`;
          await fetchReport(
            executiveUrl,
            setExecutiveSummary,
            "Executive Summary",
            (result) => {
              if (result.includes("KeyError")) {
                setExecutiveSummary(
                  "Sorry, unable to proceed with your request"
                );
              }
            }
          );
        } catch (error) {
          console.error("Error fetching Executive Summary:", error);
          setExecutiveSummaryError("Error fetching Executive Summary");
        }

        // Fetch Medication Data
        try {
          const medicationUrl = `${process.env.REACT_APP_API_URL}/Atria/medications?patient_id=${patientId}`;
          await fetchReport(
            medicationUrl,
            setMedicationParsedData,
            "Medication Data",
            (result) => {
              if (result.includes("KeyError")) {
                setMedicationParsedData([]);
                setMedicationError("Sorry, unable to proceed with your request");
              }
            }
          );
        } catch (error) {
          console.error("Error fetching Medication Data:", error);
          setMedicationError("Error fetching Medication Data");
          setMedicationParsedData([]);
        }
        try {
          const followupUrl = `${process.env.REACT_APP_API_URL}/Atria/followups?patient_id=${patientId}`;
          await fetchReport(
            followupUrl,
            setFollowupParsedData,
            "Followup Data",
            (result) => {
              if (result.includes("KeyError")) {
                setFollowupParsedData("Sorry, unable to proceed with your request");
              }
            }
          );
        } catch (error) {
          console.error("Error fetching Medication Data:", error);
          setfollowupError("Error fetching Medication Data");
        }

        // Fetch Cardio Data
        try {
          const cardioUrl = `${process.env.REACT_APP_API_URL}/Atria/cardiovascular_system?patient_id=${patientId}`;
          await fetchReport(
            cardioUrl,
            setCardioData,
            "Cardio Data",
            (result) => {
              if (result.includes("KeyError")) {
                setCardioData("Sorry, unable to proceed with your request");
              }
              //console.log("setCardioData:", cardioData, "🍔");
            }
          );
        } catch (error) {
          console.error("Error fetching Cardio Data:", error);
          setCardioError("Error fetching Cardio Data");
        }

        // Fetch Cancer Screening Data
        try {
          const cancerScreeningUrl = `${process.env.REACT_APP_API_URL}/Atria/cancer_screening?patient_id=${patientId}`;
          await fetchReport(
            cancerScreeningUrl,
            setCancerScreenData,
            "Cancer Screen Data",
            (result) => {
              if (result.includes("KeyError")) {
                setCancerScreenData(
                  "Sorry, unable to proceed with your request"
                );
              }
            }
          );
        } catch (error) {
          console.error("Error fetching Cancer Screening Data:", error);
          setCancerScreeningError("Error fetching Cancer Screening Data");
        }

        // Fetch Body Composition Data
        try {
          const bodyCompositionUrl = `${process.env.REACT_APP_API_URL}/Atria/body_composition?patient_id=${patientId}`;
          await fetchReport(
            bodyCompositionUrl,
            setBodyComposition,
            "Body Composition",
            (result) => {
              if (result.includes("KeyError")) {
                setBodyComposition(
                  "Sorry, unable to proceed with your request"
                );
              }
            }
          );
        } catch (error) {
          console.error("Error fetching Body Composition Data:", error);
          setBodyCompositionError("Error fetching Body Composition Data");
        }

        // Fetch Appointment Data
        try {
          const appointmentUrl = `${process.env.REACT_APP_API_URL}/Atria/appointment?patient_id=${patientId}`;
          await fetchReport(appointmentUrl, (result) => {
            try {
              if (result.includes("KeyError")) {
                setAppointmentText(
                  "Sorry, unable to proceed with your request"
                );
                setAppointmentText("");
                setAppointmentDf(null);
                return;
              }
              const { text, df } = JSON.parse(result);
              setAppointmentText(text);
              setAppointmentDf(df);
              setError("");
              setMedicalResponse(
                `${cardioData}\n\n${cancerScreeningData}\n\n${bodyCompositionData}`
              ); // No need to parse here, as it's already an object
            } catch (err) {
              console.error("Error parsing appointment data:", err);
              setAppointmentError("Failed to parse appointment data.");
              setAppointmentText("");
              setAppointmentDf(null);
            }
          });
        } catch (error) {
          console.error("Error fetching Appointment Data:", error);
          setAppointmentError("Error fetching Appointment Data");
          setAppointmentDf(null);
        }

        // Fetch Spikes and Trends
        try {
          const spikesAndTrendsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/Atria/spikes_and_trends?patient_id=${patientId}`
          );
          setSpikesAndTrends(spikesAndTrendsResponse.data);
        } catch (error) {
          console.error("Error fetching Spikes and Trends:", error);
          setSpikesAndTrendsError("Error fetching Spikes and Trends");
        }

        // Fetch New API Data
        try {
          const newApiUrl = `${process.env.REACT_APP_API_URL}/Atria/snowflake?patient_id=${patientId}`;
          const newApiResponse = await axios.get(newApiUrl);
          setNewApiData(newApiResponse.data);
        } catch (error) {
          console.error("Error fetching New API Data:", error);
          setNewApiDataError("Error fetching New API Data");
        }

       
        setisGsheet(true);
      } else {
        setPhysicianReport("No data found for patient ID:" + patientId);
      }
    } catch (err) {
      console.error("Error checking patient ID:", err);
      // setError("Failed to check patient ID.");
      setisGsheet(false);
    } finally {
      setExecutiveResponse(executiveSummary);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisGsheet(false);
    setChatBotInput1("");
    setShowChatBot(false);
    setSubmitted(false);
    setLoading(true); // Start loading state

    try {
      await handleFetchReports();
      //setReportsFetched(true); // Report data is fetched
      setReportGenerated(true); // Set report generated to true
    } catch (err) {
      console.error("Error fetching reports:", err); // Log the error
      // setError("Failed to fetch reports..."); // Handle error
    } finally {
      setLoading(false); // Stop loading state regardless of success or failure
    }
  };

  const handleNewId = async () => {
    try {
      // Reset all state variables

      setPatientId("");
      setPhysicianReport("");
      setMedicalReport("");
      setAbnormalityReport("");
      setExecutiveSummary("");
      setAppointmentText("");
      setAppointmentDf("");
      setSpikesAndTrends({});
      setNewApiData({});
      setGdocsLink(null);
      setLoading(false);
      setError("");
      setReportsFetched(false);
      setLoadingButton(null);
      setChatBotInput1("");
      setSelectedOption("Medical");
      setChatBotResponse("");
      setSubmitted(false);
      setMedicalResponse("");
      setMarkCitationResponse("");
      setMedicalCitationResponse("");
      setMedicationFollowupData("");
      setMedicationParsedData([]);
      setFollowupParsedData("");

      setfollowUpCitation("");
    
      setIsOpen(false);
      setReportGenerated(false);
      setBodyCitation("");
      setBodyComposition("");
      setCancerCitation("");
      setCancerScreenData("");
      setCardioData("");
      setCardioCitation("");
      setisGsheet("");
      setgsheetSMD("");
      setLabTestData(null);
      setLabtestCitation("");
      setReportGenerated(false);
      setAppError("");
      setExecutiveResponse("");
      setfollowUpCitation("");
      setAbnormalityError("");
      setPhysicianError("");
      setExecutiveSummaryError("");
      setMedicationError("");
      setCardioError("");
      setCancerScreeningError("");
      setMedicalFollowupError("");
      setNewApiDataError("");
    setNewApiData({});

      // Reset to false to show the input and button again
    } catch (err) {
      console.error("Error resetting state:", err); // Log the error
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      try {
        handleSubmitBtn();
      } catch (err) {
        console.error("Error in handleSubmitBtn:", err); // Log the error
      }
    }
  };

  const renderTable = (data) => {
    try {
      if (!data || typeof data !== "object" || Object.keys(data).length === 0) {
        return <p>No data available</p>;
      }

      const keys = Object.keys(data);

      if (!data[keys[0]] || typeof data[keys[0]] !== "object") {
        return <p>No data available</p>;
      }

      const rows = Object.keys(data[keys[0]]).map((id) => {
        const row = {};
        keys.forEach((key) => {
          row[key] = data[key]?.[id] || "N/A";
        });
        return row;
      });

      return (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                {keys.map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  {keys.map((key) => (
                    <td key={key} style={{ verticalAlign: "top" }}>
                      {row[key] || "N/A"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } catch (err) {
      //console.log("Error rendering table:", err); // Log the error to console
      return null; // Prevent the error from showing on the screen
    }
  };

  const parseAbnormalityReport = () => {
    if (!abnormalityReport) return null;

    try {
      const parsedData = JSON.parse(abnormalityReport);
      const { "Lab Reports": labReports, "Image Reports": imageReports } =
        parsedData;

      return (
        <div>
          <h1>Abnormality Report</h1>

          <Accordion
            sx={{
              backgroundColor: "#405a51",
              color: "#ded9ce",
              border: "1px solid #DED9CE",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#ded9ce" }} />}
            >
              <Typography variant="h6">Lab Reports</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {labReports === null ? (
                <p>No data available for lab report</p>
              ) : (
                renderTable(JSON.parse(labReports))
              )}
            </AccordionDetails>
          </Accordion>

          {imageReports && (
            <Accordion
              sx={{
                backgroundColor: "#405a51",
                color: "#ded9ce",
                border: "1px solid #DED9CE",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#ded9ce" }} />}
              >
                <Typography variant="h6">Image Reports</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderTable(JSON.parse(imageReports))}
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      );
    } catch (error) {
      console.error("Error parsing or rendering abnormality report:", error);
      return null;
    }
  };

  const parseAppointmentData = () => {
    if (appError) {
      return <p>{appError}</p>;
    }
    if (!appointmentDf) return null;

    let parsedData;
    try {
      parsedData = appointmentDf; // No need to parse as it is already an object
    } catch (err) {
      console.error("Error handling appointment DF:", err);
      return <p>Error handling appointment data</p>;
    }

    return (
      <div>
        <hr></hr>
        <h1>Appointment Details</h1>
        <p>{appointmentText}</p>
        <div>
          <h4>Patient Details:</h4>

          <Accordion
            sx={{
              backgroundColor: "#405a51",
              color: "#ded9ce",
              border: "1px solid #DED9CE",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#ded9ce" }} />}
            >
              <Typography variant="h6">Patient details</Typography>
            </AccordionSummary>
            <AccordionDetails>{renderTable(parsedData)}</AccordionDetails>
          </Accordion>

          <hr></hr>
        </div>
      </div>
    );
  };

  const parseNewApiData = () => {
    if (!newApiData || Object.keys(newApiData).length === 0) return null;

    const isEmptyObject = (obj) =>
      obj && typeof obj === "object" && Object.keys(obj).length === 0;
    const hasData = (data) => {
      if (Array.isArray(data)) {
        return data.length > 0;
      } else if (typeof data === "object") {
        // Check if any nested objects or arrays are non-empty
        return Object.values(data).some(
          (value) =>
            (Array.isArray(value) && value.length > 0) ||
            (typeof value === "object" && !isEmptyObject(value))
        );
      }
      return false;
    };
    const sections = Object.keys(newApiData).map((key) => {
      let data;
      try {
        if (typeof newApiData[key] === "string") {
          data = JSON.parse(newApiData[key]);
        } else {
          data = newApiData[key];
        }
      } catch (e) {
        console.error(`Error parsing data for key ${key}:`, e);
        return null;
      }

      const hasDataForKey = hasData(data);

      return (
        <Accordion
          key={key}
          sx={{
            backgroundColor: "#405a51",
            color: "#ded9ce",
            border: "1px solid #DED9CE",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ded9ce" }} />}
          >
            <Typography variant="h6">
              {key.replace("_", " ").toUpperCase()}:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasDataForKey ? (
              renderTable(data)
            ) : (
              <Typography>No data available</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      );
    });

    return (
      <div>
        <h1>Appendix</h1>
        {sections}
      </div>
    );
  };

  const renderChart = (data, comment) => {
    try {
      if (!data || Object.keys(data).length === 0) return null;

      const xValues = Object.values(data.CREATEDDATETIME);
      const yValues = Object.values(data.RESULT);

      return (
        <div className="chart-container">
          <ReactMarkdown>{comment}</ReactMarkdown>
          <Plot
            data={[
              {
                x: xValues,
                y: yValues,
                type: "scatter",
                mode: "lines+markers",
                marker: { color: "red" },
              },
            ]}
            layout={{
              plot_bgcolor: "#ded9ce",
              paper_bgcolor: "#ded9ce",
              xaxis: {
                title: "Time",
                color: "#304b42",
                gridcolor: "#304b42",
              },
              yaxis: {
                title: "Observation Result",
                color: "#304b42",
                gridcolor: "#304b42",
              },
            }}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      );
    } catch (err) {
      console.error("Error rendering chart:", err); // Log the error
      return <p>Error rendering chart</p>; // Display a user-friendly message
    }
  };

  //let combinedContent = encodeURIComponent(`${physicianReport}\\n\n${apiResponse}`);

  // const handlegooglelink = async () => {
  //   setLoadingButton("googleOriginal");
  //   const combinedContent = {
  //     ...tempDict,
  //     // ...medicationFollowUpData
  //   };
  //   if (combinedContent.appointment.includes("No new")) {
  //     combinedContent.appointment = "None";
  //   }

  //   const timestamp = new Date()
  //     .toLocaleString("en-US", {
  //       year: "numeric",
  //       month: "2-digit",
  //       day: "2-digit",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       second: "2-digit",
  //       hour12: false,
  //     })
  //     .replace(/[/,:]/g, ":");
  //   const fileName = `${patientId} PHA (${timestamp})`;

  //   const inputData = {
  //     first_name: firstName,
  //     last_name: lastName,
  //     email: email,
  //     sessionID: sessionID,
  //     title: fileName,
  //   };
  //   const updatedCombinedContent = { ...combinedContent, ...inputData };

  //   try {
  //     let link = gdocsLink;
  //     if (!link) {
  //       const gdocsResponse = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/Atria/gdocs_link`,
  //         updatedCombinedContent
  //       );
  //       link = gdocsResponse.data;
  //       setGdocsLink(link);
  //     }
  //     window.open(link);
  //   } catch (error) {
  //     console.error("Error generating Google Docs link:", error);
  //   } finally {
  //     setLoadingButton(null);
  //   }
  // };



  const handlegooglelink = async () => {
    setLoadingButton("googleOriginal");
    const combinedContent = {
      ...tempDict,
      // ...medicationFollowUpData
    };
    if (combinedContent.appointment.includes("No new")) {
      combinedContent.appointment = "None";
    }

    // const timestamp = new Date()
    //   .toLocaleString("en-US", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //     hour12: false,
    //   })
    //   .replace(/[/,:]/g, ":");

    const timestamp = new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$4:$5:$6_$1-$2-$3');
    
    //console.log(timestamp);
    
    const fileName = `${patientId} PHA (${timestamp})`;

    const inputData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      sessionID: sessionID,
      title: fileName,
    };
    const updatedCombinedContent = { ...combinedContent, ...inputData };

    try {
      let link = gdocsLink;
      if (!link) {
        const gdocsResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/Atria/gdocs_link`,
          updatedCombinedContent
        );
        link = gdocsResponse.data;
        setGdocsLink(link);
      }
      window.open(link);
    } catch (error) {
      console.error("Error generating Google Docs link:", error);
    } finally {
      setLoadingButton(null);
    }
  };


  const renderMedicationTable = (data) => {
    // console.log("inside render table😈");
  
    // Check if data is empty or not an array
    if (!Array.isArray(data) || data.length === 0) {
      return <p>No data available</p>;
    }
  
    // Render the first list (more basic medication information)
    const basicMedicationData = data[0];
    const basicColumns = basicMedicationData.length > 0 ? Object.keys(basicMedicationData[0]) : [];
  
    const BasicMedicationTable = () => (
      <div className="dynamic-table-container overflow-x-auto my-4 report">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              {basicColumns.map((column) => (
                <th
                  key={column}
                  className="border border-gray-300 px-4 py-2 bg-gray-100 text-left sticky top-0 bg-white z-10"
                >
                  {column.charAt(0).toUpperCase() + column.slice(1).toLowerCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {basicMedicationData.map((item, index) => (
              <tr key={index} className="hover:bg-gray-50">
                {basicColumns.map((column) => (
                  <td
                    key={column}
                    className="border border-gray-300 px-4 py-2"
                  >
                    {item[column] !== null && item[column] !== undefined && item[column]!==""
                      ? String(item[column])
                      : 'N/A'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  
    // Render the second list (more detailed medication information)
    const detailedMedicationData = data[1];
    const detailedColumns = detailedMedicationData.length > 0 ? Object.keys(detailedMedicationData[0]) : [];
  
    // Wrapper component to handle useState
    const MedicationTableWrapper = () => {
      const [detailedTableExpanded, setDetailedTableExpanded] = useState(false);
  
      const toggleDetailedTable = () => {
        setDetailedTableExpanded(!detailedTableExpanded);
      };
  
      return (
        <>
          <BasicMedicationTable />
  
          <Accordion
            
          
            sx={{
              backgroundColor: "#405a51",
              color: "#ded9ce",
              border: "1px solid #DED9CE",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#ded9ce" }} />}
            >
              <Typography variant="h6">Medication Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div 
                className="table-container ci-table-container"
               
              >
                <table >
                  <thead>
                    <tr>
                      {detailedColumns.map((column) => (
                        <th
                          key={column}
                         
                        >
                          {column}
  
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {detailedMedicationData.map((item, index) => (
                      <tr key={index} >
                        {detailedColumns.map((column) => (
                          <td
                            key={column}
                            style={{ verticalAlign: "top" }}
                           
                          >
                            {item[column] !== null && item[column] !== undefined
                              ? String(item[column])
                              : 'N/A'}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      );
    };
  
    return <MedicationTableWrapper />;
  };
  
  const renderTables = (data) => {
    // Check if data is defined
    if (!data) {
      return <div>No data available to display.</div>;
    }

    const { document_id, appointment_id } = data;

    const renderTable = (tableName, tableData) => {
      try {
        // Check if there's any data to display for the given table
        if (!tableData || tableData.length === 0) {
          return (
            <div key={tableName}>
              <p>
                <br></br>
              </p>
            </div>
          );
        }

        // Determine the columns from the first row
        const columns = Object.keys(tableData[0]);

        return (
          <Accordion
            sx={{
              backgroundColor: "#405a51",
              color: "#ded9ce",
              border: "1px solid #DED9CE",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#ded9ce" }} />}
            >
              <Typography variant="h6">
                Citation source based on {tableName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="table-container ci-table-container">
                <table>
                  <thead>
                    <tr>
                      {columns.map((column) => (
                        <th key={column}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {columns.map((column) => (
                          <td key={column} style={{ verticalAlign: "top" }}>
                            {row[column]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      } catch (error) {
        console.error(`Error rendering table "${tableName}":`, error);
        return null; // Continue rendering without crashing

      }
    };

    return (
      <div>
        {renderTable("Document ID", document_id)}
        {renderTable("Appointment ID", appointment_id)}
      </div>
    );
  };

  ///============

  // const handleDownload = async () => {
  //   const combinedContentDownload = {
  //     ...tempDict,
  //   };
  //   if (combinedContentDownload.appointment.includes("No new")) {
  //     combinedContentDownload.appointment = "None";
  //   }

  //   setLoadingButton("downloadOriginal");
  //   try {
  //     const timestamp = new Date()
  //       .toLocaleString("en-US", {
  //         year: "numeric",
  //         month: "2-digit",
  //         day: "2-digit",
  //         hour: "2-digit",
  //         minute: "2-digit",
  //         second: "2-digit",
  //         hour12: false,
  //       })
  //       .replace(/[/,:]/g, ":");
  //     const fileName = `${patientId} PHA (${timestamp})`;
  //     const inputData = {
  //       first_name: firstName,
  //       last_name: lastName,
  //       email: email,
  //       sessionID: sessionID,
  //       title: fileName,
  //     };

  //     const updatedCombinedContent = {
  //       ...combinedContentDownload,
  //       ...inputData,
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/Atria/PHA_download`,
  //       updatedCombinedContent,
  //       {
  //         responseType: "arraybuffer",
  //       }
  //     );

  //     const blob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //     });

  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `${fileName}.docx`;
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     a.remove();

  //     ////console.log(`File saved as report_${patientId}.docx`);
  //   } catch (error) {
  //     console.error("Error downloading document:", error);
  //   } finally {
  //     setLoadingButton(null);
  //   }
  // };


  const handleDownload = async () => {
    const combinedContentDownload = {
      ...tempDict,
    };
    if (combinedContentDownload.appointment.includes("No new")) {
      combinedContentDownload.appointment = "None";
    }

    setLoadingButton("downloadOriginal");
    try {
      // const timestamp = new Date()
      //   .toLocaleString("en-US", {
      //     year: "numeric",
      //     month: "2-digit",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     second: "2-digit",
      //     hour12: false,
      //   })
      //   .replace(/[/,:]/g, ":");

      const timestamp = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$4:$5:$6_$1-$2-$3');
      
      //console.log(timestamp);
      



      const fileName = `${patientId} PHA (${timestamp})`;
      const inputData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        sessionID: sessionID,
        title: fileName,
      };

      const updatedCombinedContent = {
        ...combinedContentDownload,
        ...inputData,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Atria/PHA_download`,
        updatedCombinedContent,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.docx`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

      ////console.log(`File saved as report_${patientId}.docx`);
    } catch (error) {
      console.error("Error downloading document:", error);
    } finally {
      setLoadingButton(null);
    }
  };




  // New functions for edit and save functionality
  const toggleEdit = (section) => {
    setEditState((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleSave = async (section) => {
    ////console.log("save button troggered 😎😎");
    toggleEdit(section);

    if (section === "executiveSummary") {
      try {
        // const result = executiveSummary;

        const markCitationResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
          {
            summary_data: executiveSummary,
            patient_id: patientId,
            // Pass the edited executive summary
          }
        );

        const markCitationData = markCitationResponse.data;
        setMarkCitationResponse(markCitationData);
      } catch (error) {
        console.error("Error saving executive summary:", error);
      }
    }

    if (section === "medicalReport") {
      try {
        const medicalCitationResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/Atria/mark_citation`,
          {
            summary_data: medicalReport,
            patient_id: patientId,
          }
        );
        const medicalCitationData = await medicalCitationResponse.data;
        setMedicalCitationResponse(medicalCitationData);
      } catch (error) {
        console.error("Error saving medical report", error);
      }
    }
  };

  const renderEditableSection = (content, section) => {
    try {
      return editState[section] ? (
        <div>
          <textarea
            value={content}
            onChange={(e) => {
              try {
                switch (section) {
                  case "physicianReport":
                    setPhysicianReport(e.target.value);
                    break;
                  case "medicalReport":
                    setMedicalReport(e.target.value);
                    break;
                  case "executiveSummary":
                    setExecutiveSummary(e.target.value);
                    ////console.log("testing", e.target.value);
                    break;
                 

                  default:
                    console.warn(`Unhandled section: ${section}`);
                }
              } catch (innerErr) {
                console.error("Error updating section:", innerErr); // Log the error
              }
            }}
            rows={Math.min(50, Math.max(3, Math.ceil(content.length / 100)))}
            cols={Math.min(100, Math.max(20, Math.ceil(content.length / 10)))}
          />
          <button onClick={() => handleSave(section)}>Save</button>
        </div>
      ) : (
        <div>
          <ReactMarkdown>{content}</ReactMarkdown>
          <button onClick={() => toggleEdit(section)}>Edit</button>
        </div>
      );
    } catch (err) {
      console.error("Error rendering editable section:", err); // Log the error
      return <p>Error rendering section</p>; // Display a user-friendly message
    }
  };

  const fetchContent = async (url, data, isStreaming) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      if (isStreaming) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let result = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          result += value;
          setChatBotResponse((prev) => prev + value);
        }

        ////console.log(`Fetched response:`, result);
      } else {
        const result = await response.json();
        setChatBotResponse(result.content);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
      // Handle errors here
      // setErrorMessage('Failed to fetch content. Please try again.');
    }
  };
 
  

  const handleSubmitBtn = async () => {
    try {
      setLoading(true);
      setChatBotResponse("");
      setSubmitted(true);

      let content;
      let combinedContentExe = `${executiveSummary}\n${medicationFollowUpData}`;
      if (selectedOption === "Medical") {
        content = medicalResponse === "" ? medicalResponse : medicalResponse;
      } else {
        content =
          executiveResponse === "" ? combinedContentExe : executiveResponse;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Atria/AIeditorPHA`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input_prompt: chatBotInput1,
            module: selectedOption,
            content: content,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let result = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += value;
        setChatBotResponse((prev) => prev + value);

        if (selectedOption === "Medical") {
          // setMedicalResponse(prev => prev + value);
          setMedicalResponse(result);
        } else if (selectedOption === "Executive") {
          setExecutiveResponse(result);
          // setExecutiveResponse(prev => prev + value);
        }
      }

      ////console.log("Fetched response:", result);
      setLoading(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      setLoading(false);
      // Handle errors here
    }
  };

  // const handlegooglelinkEdited = async () => {
  //   setLoadingButton("googleEdited");
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/Atria/gdocs_link`,
  //       {
  //         patient_id: patientId,
  //         executive_summary:
  //           selectedOption === "Medical" ? executiveResponse : executiveSummary,
  //         medical_summary:
  //           selectedOption === "Medical" ? medicalResponse : medicalReport,
  //         appointment: appointmentText, // Add your appointment text here
  //       }
  //     );
  //     const link = response.data;
  //     setGdocsLink(link);
  //     window.open(link);
  //   } catch (error) {
  //     console.error("Error generating edited Google Docs link:", error);
  //   } finally {
  //     setLoadingButton("");
  //   }
  // };

  const handlegooglelinkEdited = async () => {
    setLoadingButton("googleEdited");
  
    // Construct the payload similarly to the original function
    const combinedContent = {
      patient_id: patientId,
      // original  executive_summary: selectedOption === "Medical" ? executiveResponse : executiveSummary,
      // medical_summary: selectedOption === "Executive" ? medicalResponse : medicalReport,


      // executive_summary: selectedOption === "Medical" ? executiveResponse : executiveSummary,
      // medical_summary: selectedOption === "Executive" ? medicalReport : medicalResponse,


      // medical_summary: selectedOption === "Medical" ? medicalResponse : medicalReport,
      // executive_summary: selectedOption === "Executive" ? executiveResponse :executiveSummary,

      medical_summary: selectedOption === "Executive" ? medicalResponse : medicalResponse,
executive_summary: selectedOption === "Medical" ? executiveSummary : executiveResponse,

      appointment: appointmentText.includes("No new") ? "None" : appointmentText, // Ensure appointment text is handled
    };
  
    // Add input data as in the original
    const timestamp = new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$4:$5:$6_$1-$2-$3');
    
    //console.log(timestamp);
    
    
    //console.log(timestamp);
    
    const fileName = `${patientId} PHA (${timestamp})`;
  
    const inputData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      sessionID: sessionID,
      title: fileName,
      medications:medicationFollowUpData,
      followups: followupParsedData,
    };
  
    // Merge the content and input data
    const updatedCombinedContent = { ...combinedContent, ...inputData };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Atria/gdocs_link`,
        updatedCombinedContent
      );
      const link = response.data;
      setGdocsLink(link);
      window.open(link);
    } catch (error) {
      console.error("Error generating edited Google Docs link:", error);
    } finally {
      setLoadingButton("");
    }
  };
  
  // const handleDownloadEdited = async () => {
  //   setLoadingButton("downloadEdited");
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/Atria/PHA_download`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           patient_id: patientId,
  //           executive_summary:
  //             selectedOption === "Executive"
  //               ? executiveResponse
  //               : executiveSummary,
  //           medical_summary:
  //             selectedOption === "Medical" ? medicalResponse : medicalReport,
  //           appointment: appointmentText, // Add your appointment text here
  //         }),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `report_${patientId}.docx`;
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     a.remove();

  //     ////console.log(`File saved as report_${patientId}.docx`);
  //   } catch (error) {
  //     console.error("Error downloading document:", error);
  //   } finally {
  //     setLoadingButton(null);
  //   }
  // };


  const handleDownloadEdited = async () => {
    setLoadingButton("downloadEdited");
  
    const combinedContentDownload = {
      ...tempDict,
    };
    if (combinedContentDownload.appointment.includes("No new")) {
      combinedContentDownload.appointment = "None";
    }
  
    try {
      const timestamp = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$4:$5:$6_$1-$2-$3');
      
      //console.log(timestamp);
      
      
      //console.log(timestamp);
      
  
      const fileName = `${patientId} PHA (${timestamp})`;
  
      const inputData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        sessionID: sessionID,
        title: fileName,
      };
  
      const updatedCombinedContent = {
        ...combinedContentDownload,
      

        medical_summary: selectedOption === "Executive" ? medicalResponse : medicalResponse,
        executive_summary: selectedOption === "Medical" ? executiveSummary : executiveResponse,


        appointment: appointmentText.includes("No new") ? "None" : appointmentText, // appointment text from form
        ...inputData,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Atria/PHA_download`,
        updatedCombinedContent,
        {
          responseType: "arraybuffer",
        }
      );
  
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
  
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.docx`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
  
    } catch (error) {
      console.error("Error downloading document:", error);
    } finally {
      setLoadingButton(null);
    }
  };
  

  

  const convertToMarkdown = (text) => {
    // Example of basic conversion logic. This can be expanded based on your specific needs.
    return text
      .replace(/(?:\r\n|\r|\n)/g, "  \n") // Convert newlines to Markdown line breaks
      .replace(/^\s*-\s*/gm, "* "); // Convert bullet points to Markdown
  };

  const { isSidebarOpen, handleSidebarToggle } = useSidebarState();

  return (
    <div className="newpage-background">
      <Sidebar
        isOpen={isSidebarOpen}
        onToggle={handleSidebarToggle}
        reportsFetched={reportsFetched}
        loadingButton={loadingButton}
        handleDownload={handleDownload}
        handlegooglelink={handlegooglelink}
      />
      <div
        className={`newpage-container ${
          isSidebarOpen ? "sidebar-open" : "sidebar-closed"
        }`}
      >
      
        <div className="newpage-box">
          <h2>Personalised Health Assessment Report Generator</h2>
          <h5>
            {/* Hi {firstName} {lastName}, Welcome to Atria PHA. Enter your patient */}
            Hi {firstName}, Welcome to Atria PHA. Enter your patient

            ID to get started.
          </h5>

          {error && <div className="error">{error}</div>}

          {!reportGenerated ? (
            <form onSubmit={handleSubmit}>
              <input
                className="inbtnsel"
                value={patientId}
                onChange={(e) => setPatientId(e.target.value)}
                placeholder="Enter your patient ID"
                required
              />
              <button
                className="getReport inbtnsel"
                type="submit"
                disabled={loading}
              >
                {loading ? "Generating Report..." : "Get Report"}
              </button>
            </form>
          ) : (
            <button className="start-new-id inbtnsel" onClick={handleNewId}>
              Start with New ID
            </button>
          )}
        </div>
        <div id="report-container" className="report-container">
          <div id="abnormality-report" className="report">
            {parseAbnormalityReport()}
          </div>

          {/*           
          {physicianReport && (
            <div id="physician-report" className="report">
              <hr />
              <h1>Physician Reference</h1>
              {renderEditableSection(physicianReport, "physicianReport")}
            </div>
          )} */}

          {PhysicianError ? (
            <p>{PhysicianError}</p>
          ) : (
            physicianReport && (
              <div id="physician-report" className="report">
                <hr />
                <h1>Physician Reference</h1>
                {renderEditableSection(physicianReport, "physicianReport")}
              </div>
            )
          )}

          {executiveSummary && !markCitationResponse && (
            <div id="executive-summary" className="report">
              <hr />
              <h1>Executive Summary</h1>
              {renderEditableSection(executiveSummary, "executiveSummary")}
            </div>
          )}

          {executiveSummary &&
            markCitationResponse && ( ////console.log(markCitationResponse),
              <div>
                <h1 id="executive-summary" className="report">
                  Executive Summary
                </h1>

                {Object.keys(markCitationResponse).map((paragraph, index) => (
                  <div>
                    <div key={index}>
                      <p className="citation-para">
                        <ReactMarkdown>{paragraph}</ReactMarkdown>
                      </p>

                      {renderTables(markCitationResponse[paragraph])}
                    </div>
                  </div>
                ))}

                <div className="edit-executive-summary">
                  <button
                    style={{ marginBottom: "10px" }}
                    onClick={() => {
                      if (editState.executiveSummary) {
                        handleSave("executiveSummary");
                      } else {
                        toggleEdit("executiveSummary");
                      }
                    }}
                  >
                    {editState.executiveSummary ? "Save" : "Edit"}
                  </button>
                  <hr></hr>

                  {editState.executiveSummary && (
                    <div>
                      <textarea
                        value={executiveSummary}
                        onChange={(e) => setExecutiveSummary(e.target.value)}
                        rows={Math.min(
                          50,
                          Math.max(3, Math.ceil(executiveSummary.length / 100))
                        )}
                        cols={Math.min(
                          100,
                          Math.max(20, Math.ceil(executiveSummary.length / 10))
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
    { medicationParsedData.length>0 &&(
      <>
      <h3 className="report">Current Medications</h3>
    
      </>
    )}        

{MedicationError ? (
  <p>{MedicationError}</p>
) : (
  medicationParsedData && medicationParsedData.length > 0 && renderMedicationTable(medicationParsedData)
)}




          {MedicalFollowupError ? (
            <p>{MedicalFollowupError}</p>
          ) : (
            followupParsedData && (

  

              <div className="report">
              {Object.keys(followUpCitation).map((paragraph, index) => (
                <div>
                  <div key={index}>
                    <p className="citation-para">
                      <ReactMarkdown>{paragraph}</ReactMarkdown>
                    </p>

                    {renderTables(followUpCitation[paragraph])}
                  </div>
                </div>
              ))}
              </div>

            )
          )}

          {CardioError ? (
            <p>{CardioError}</p>
          ) : (
            cardioData &&
            cardioCitationResponse && (
              <div>
                <hr style={{ "margin-top": "15px" }}></hr>
                <h1 style={{ color: "#ded9ce" }}>
                  Summary of medical testing{" "}
                </h1>

                {Object.keys(cardioCitationResponse).map((paragraph, index) => (
                  <div id="medical-report" className="report" key={index}>
                    <p className="citation-para">
                      <ReactMarkdown>
                        {paragraph}
                      </ReactMarkdown>
                    </p>
                    {renderTables(cardioCitationResponse[paragraph])}
                  </div>
                ))}
              </div>
            )
          )}

          {CancerScreeningError ? (
            <p>{CancerScreeningError}</p>
          ) : (
            cancerScreeningData &&
            cancerCitationResponse && (
              <div>
                <hr style={{ "margin-top": "15px" }}></hr>

                {Object.keys(cancerCitationResponse).map((paragraph, index) => (
                  <div id="medical-report" className="report" key={index}>
                    <p className="citation-para">
                      <ReactMarkdown>
                        {paragraph}
                      </ReactMarkdown>
                    </p>
                    {renderTables(cancerCitationResponse[paragraph])}
                  </div>
                ))}
              </div>
            )
          )}

          {BodyCompositionError ? (
            <p>{BodyCompositionError}</p>
          ) : (
            bodyCompositionData &&
            bodyCitationResponse && (
              <div>
                <hr style={{ "margin-top": "15px" }}></hr>

                {Object.keys(bodyCitationResponse).map((paragraph, index) => (
                  <div id="medical-report" className="report" key={index}>
                    <p className="citation-para">
                      <ReactMarkdown>
                       {paragraph}
                      </ReactMarkdown>
                    </p>
                    {renderTables(bodyCitationResponse[paragraph])}
                  </div>
                ))}
              </div>
            )
          )}

          {labTestData && (
            <div>
              <hr style={{ "margin-top": "15px" }}></hr>

              {Object.keys(labTestCitation).map((paragraph, index) => (
                <div id="medical-report" className="report" key={index}>
                  <p className="citation-para">
                    <ReactMarkdown>
                      {paragraph}
                    </ReactMarkdown>
                  </p>
                  {renderTables(labTestCitation[paragraph])}
                </div>
              ))}
            </div>
          )}

          {appointmentText && (
            <div id="appointment-report" className="report">
              {/* {renderEditableSection(appointmentText, "appointmentText")} */}
              {parseAppointmentData()}
            </div>
          )}

          {spikesAndTrends.total_spike_commentry &&
            Array.isArray(spikesAndTrends.total_spike_commentry) && (
              <>
                <h1 style={{ color: "#ded9ce" }}>Spikes</h1>

                {spikesAndTrends.total_spike_commentry.map((item, index) => (
                  <div id="spike-report" className="report" key={index}>
                    {renderChart(item.data, item.comment)}
                  </div>
                ))}
              </>
            )}

          {spikesAndTrends.total_trend_commentry &&
            Array.isArray(spikesAndTrends.total_trend_commentry) && (
              <>
                <hr></hr>
                <h1 style={{ color: "#ded9ce" }}>Trends</h1>

                {spikesAndTrends.total_trend_commentry.map((item, index) => (
                  <div id="trend-report" className="report" key={index}>
                    {renderChart(item.data, item.comment)}
                  </div>
                ))}
              </>
            )}

          <div id="new-api-report" className="report">
            {parseNewApiData()}
          </div>





          {reportsFetched && (
            <div className="button-container">
              {!showChatBot && (
                <button
                  className="chatbot-btn"
                  onClick={() => setShowChatBot(true)}
                >
                  Chat Bot
                </button>
              )}

              <button
                className={`moveToTop ${!scrolledToTop ? "" : "rotated"}`}
                onClick={handleMoveToTop}
              >
                <img src="/upload.png" alt="Move to Top" />
              </button>
            </div>
          )}


 {showChatBot && (
            <div className="chat-bot-container">
              <input
                type="text"
                className="chatBotInput"
                placeholder="Add, remove, rephrase the generated report"
                value={chatBotInput1}
                onKeyDown={handleKeyDown}
                onChange={(e) => setChatBotInput1(e.target.value)}
              />
              <select
                className="chatBotDropdown"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="Medical">Medical</option>
                <option value="Executive">Executive</option>
              </select>
              <button className="chatBotSubmit" onClick={handleSubmitBtn}>
                Submit
              </button>
            </div>
          )}

          {submitted && (
            <div className="report">
              <ReactMarkdown>{physicianReport}</ReactMarkdown>

              {selectedOption === "Medical" && (
                <>
                  <h3>Executive Summary</h3>
                  <ReactMarkdown>{executiveSummary}</ReactMarkdown>
                  <ReactMarkdown>{medicalResponse}</ReactMarkdown>
                </>
              )}

              {selectedOption === "Executive" && (
                <>
                 

                  <ReactMarkdown>{executiveResponse}</ReactMarkdown>
                  <ReactMarkdown>{medicalResponse}</ReactMarkdown>
                </>
              )}

              {reportsFetched && (
                <div className="button-container">
                  <button
                    className="getReport inbtnsel"
                    onClick={handleDownloadEdited}
                    disabled={loadingButton === "downloadEdited"}
                  >
                    {loadingButton === "downloadEdited"
                      ? "Loading..."
                      : "Edited Download Docs"}
                  </button>
                  <button
                    className="getReport inbtnsel"
                    onClick={handlegooglelinkEdited}
                    disabled={loadingButton === "googleEdited"}
                  >
                    {loadingButton === "googleEdited"
                      ? "Loading..."
                      : "Edited Google Docs"}
                  </button>
                </div>
              )}
            </div>
          )}

          {loading && <div className="loading">Generating...</div>}
        </div>
      </div>
    </div>
  );
};

export default NewPage;
